import "./App.css"
import { BrowserRouter, Routes, Route } from "react-router-dom"
import "bootstrap/dist/css/bootstrap.min.css"
import { QueryClient, QueryClientProvider } from "@tanstack/react-query"
import { ReactQueryDevtools } from "@tanstack/react-query-devtools"
import { RealmAppProvider } from "./realm/RealmAppProvider"
import RealmApolloProvider from "./graphql/realmApolloClient"
import React from "react"
import { Chart, ArcElement } from "chart.js"
import { ClerkProvider, SignedIn, SignedOut, RedirectToSignIn } from "@clerk/clerk-react"
import "react-toastify/dist/ReactToastify.css"

const DefaultLayout = React.lazy(() => import("./components/shared/Layout"))

Chart.register(ArcElement)

function App() {
	const appId = process.env.REACT_APP_REALM_APP_ID
	const publishableKey = process.env.REACT_APP_CLERK_PUBLISHABLE_KEY
	// Create a client
	const queryClient = new QueryClient({
		defaultOptions: {
			logger: {
				log: console.log,
				warn: console.warn,
				error: console.error,
			},
			queries: {
				retry: false,
				refetchOnWindowFocus: false,
				refetchOnMount: false,
			},
		},
	})
	return (
		<RealmAppProvider appId={appId}>
			<RealmApolloProvider>
				<QueryClientProvider client={queryClient}>
					<ClerkProvider publishableKey={publishableKey}>
						<BrowserRouter>
							<SignedIn>
								<Routes>
									<Route path="*" element={<DefaultLayout />} />
								</Routes>
							</SignedIn>
							<SignedOut>
								<RedirectToSignIn />
							</SignedOut>
						</BrowserRouter>
						<ReactQueryDevtools initialIsOpen={false} />
					</ClerkProvider>
				</QueryClientProvider>
			</RealmApolloProvider>
		</RealmAppProvider>
	)
}

export default App
